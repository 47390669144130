import axios from "axios";
import { cache } from "../services/cache-service";

export function isLoggedIn() {
    return !!cache.auth.get();
}

export async function validateApiKey(apiKey: string) {
    try {
        const path = `${process.env.REACT_APP_API_URL}/v1/forms`;
        await axios.get<Blob>(path, {
            headers: { "x-api-key": apiKey },
        });
        cache.auth.save(apiKey);
        return { valid: true, apiKey };
    } catch (err) {
        if (err.response.status === 401) {
            return {
                valid: false,
                errorMessage: "Invalid API Key",
                error: err,
            };
        }
        console.warn(err);
        return { valid: false, errorMessage: err.message, error: err };
    }
}

export async function logOut() {
    cache.auth.delete();
}

export function getApiKey(): string {
    if (!isLoggedIn()) {
        return null;
    }
    return cache.auth.get();
}
