import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IFormPage } from "../../../../models/applicationState";
import { cache } from "../../../../services/cache-service";
import {
    getFormPage,
    createFormVersionPage,
    updateFormTypeVersionPage,
} from "../../../../services/project-service";
import { GenericCreateEdit } from "../../common/generic-create-edit";
import { schema } from "./schema";

export function FormTypeVersionPagesCrud({ match }) {
    const history = useHistory();
    const { formId, formTypeId, formTypeVersionId, formTypeVersionPageId } =
        match.params;
    const [data, setData] = useState<IFormPage>(null);
    useEffect(() => {
        (async () => {
            if (!formTypeVersionPageId) {
                return;
            }
            try {
                const data = await getFormPage(
                    formId,
                    formTypeId,
                    formTypeVersionId,
                    formTypeVersionPageId
                );
                setData(data);
            } catch (err) {
                console.error(err);
            }
        })();
    }, [formId, formTypeId, formTypeVersionId, formTypeVersionPageId]);
    if (formTypeVersionPageId && !data) {
        return null;
    }

    return (
        <GenericCreateEdit
            title="Form Type Version Page"
            schema={schema}
            initialFormData={data}
            onAdded={onSuccess}
            onUpdated={onSuccess}
            createFunction={async (formTypeVersionPage) =>
                createFormVersionPage(
                    formId,
                    formTypeId,
                    formTypeVersionId,
                    formTypeVersionPage
                )
            }
            updateFunction={async (formTypeVersionPage) => {
                await updateFormTypeVersionPage(
                    formId,
                    formTypeId,
                    formTypeVersionId,
                    formTypeVersionPage
                );
                cache.selectedPage.checkUpdate(formTypeVersionPage);
            }}
            onCancel={() => history.goBack()}
        />
    );

    function onSuccess() {
        history.push(`/select-form`);
    }
}
