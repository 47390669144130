import React from "react";
import { IProject } from "../../../../models/applicationState";

interface Props {
    project: IProject;
}
export function BreadCrumbs({ project }: Props) {
    return (
        <div style={{ textAlign: "center" }}>
            {project.form.title} {">"} {project.formType.title} {">"}{" "}
            {project.formVersion.title} {">"} {project.formPage.title}
        </div>
    );
}
