import { axiosInstance } from "./api-service";
import {
    IProject,
    IForm,
    IFormType,
    IFormVersion,
    IFormPage,
    IFormPageFile,
    IFieldInfo,
    ILabelData,
    AssetLabelingState,
    IPageTrainingLog,
    IRebuildModelTrainingLog,
} from "../models/applicationState";
import { FormPageArray } from "@azure/ai-form-recognizer";
import _ from "lodash";

/** Forms */

export async function createForm(form: IForm): Promise<IForm> {
    return (await axiosInstance().post("/v1/forms", form)).data;
}

export async function updateForm(form: IForm): Promise<void> {
    return (await axiosInstance().put(`/v1/forms/${form.title}`, form)).data;
}

export async function getForms(): Promise<IForm[]> {
    return (await axiosInstance().get("/v1/forms")).data;
}

export async function getForm(formName): Promise<IForm> {
    return (await axiosInstance().get(`/v1/forms/${formName}`)).data;
}

/** Form Types */
export async function createFormType(
    formName: string,
    form: IFormType
): Promise<IFormType> {
    return (await axiosInstance().post(`/v1/forms/${formName}/types`, form))
        .data;
}

export async function updateFormType(
    formName: string,
    formType: IFormType
): Promise<void> {
    return (
        await axiosInstance().put(
            `/v1/forms/${formName}/types/${formType.name}`,
            formType
        )
    ).data;
}

export async function getFormTypes(formName: string): Promise<IFormType[]> {
    return (await axiosInstance().get(`/v1/forms/${formName}/types`)).data;
}

export async function getFormType(
    formName: string,
    formType: string
): Promise<IFormType> {
    return (
        await axiosInstance().get(`/v1/forms/${formName}/types/${formType}`)
    ).data;
}

/** Form Type Versions */
export async function createFormVersion(
    formName: string,
    typeName: string,
    formVersion: IFormVersion
): Promise<IFormVersion> {
    return (
        await axiosInstance().post(
            `/v1/forms/${formName}/types/${typeName}/versions`,
            formVersion
        )
    ).data;
}

export async function updateFormTypeVersion(
    formName: string,
    typeName: string,
    formVersion: IFormVersion
): Promise<void> {
    return (
        await axiosInstance().put(
            `/v1/forms/${formName}/types/${typeName}/versions/${formVersion.name}`,
            formVersion
        )
    ).data;
}

export async function getFormVersions(
    formName: string,
    typeName: string
): Promise<IFormVersion[]> {
    return (
        await axiosInstance().get(
            `/v1/forms/${formName}/types/${typeName}/versions`
        )
    ).data;
}
export async function getFormVersion(
    formName: string,
    typeName: string,
    versionName: string
): Promise<IFormVersion> {
    return (
        await axiosInstance().get(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}`
        )
    ).data;
}

/** Form Type Version Pages */
export async function createFormVersionPage(
    formName: string,
    typeName: string,
    versionName: string,
    formVersionPage: IFormPage
): Promise<IFormPage> {
    return (
        await axiosInstance().post(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages`,
            formVersionPage
        )
    ).data;
}

export async function updateFormTypeVersionPage(
    formName: string,
    typeName: string,
    versionName: string,
    formVersionPage: IFormPage
): Promise<void> {
    return (
        await axiosInstance().put(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${formVersionPage.name}`,
            formVersionPage
        )
    ).data;
}

export async function getFormPages(
    formName: string,
    typeName: string,
    versionName: string
): Promise<IFormPage[]> {
    return (
        await axiosInstance().get(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages`
        )
    ).data;
}

export async function getFormPage(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string
): Promise<IFormPage> {
    return (
        await axiosInstance().get(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}`
        )
    ).data;
}

/** Form Type Version Page Files */
export async function getFormPageFiles(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string
): Promise<IFormPageFile[]> {
    let data: IFormPageFile[] = (
        await axiosInstance().get(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/files?$select=_id,formId,formTypeId,formTypeVersionId,formTypeVersionPageId,mimetype,filename,blobName,containerName,containerPrefix,fileGuid,extension,_dateUpdated,labels`
        )
    ).data;
    data = data.map((d) => {
        d.labels = mapSchemaAfterRetrieve(d.labels);
        return d;
    });
    return data;
}

export async function getFormPageFile(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fileId: string
): Promise<IFormPageFile> {
    const data: IFormPageFile = (
        await axiosInstance().get(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/files/${fileId}`
        )
    ).data;
    data.labels = mapSchemaAfterRetrieve(data.labels);
    return data;
}

export async function deleteFormPageFile(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fileId: string
): Promise<void> {
    return axiosInstance().delete(
        `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/files/${fileId}`
    );
}

export async function downloadFile(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fileId: string
): Promise<void> {
    const [metadata, response] = await Promise.all([
        getFormPageFile(formName, typeName, versionName, pageName, fileId),
        axiosInstance().get(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/files/${fileId}/download`,
            { responseType: "blob" }
        ),
    ]);
    const filename = `${metadata.filename}.${metadata.extension}`;
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}

export async function uploadFile(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    file: File
): Promise<IFormPageFile> {
    const body = {
        filename: extractFileName(file.name),
        mimetype: file.type,
        file: await file2Base64(file),
    };
    const response = await axiosInstance().post(
        `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/files`,
        body
    );
    return response.data;
}

function extractFileName(fullFileName: string): string {
    const lastIndex = fullFileName.lastIndexOf(".");
    return fullFileName.substring(0, lastIndex);
}

function file2Base64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {};
        reader.onloadend = () => {
            const allData = reader.result.toString();
            if (!allData) {
                reject(new Error("reader.result was empty"));
            }
            //resolve(allData);
            const startIndex = allData.indexOf(",") + 1;
            const fileData = allData.substring(startIndex);
            resolve(fileData);
        };
        reader.onerror = (error) => reject(error);
    });
}

/** Form Type Version Page Fields */
export async function getFormPageFields(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string
): Promise<IFieldInfo> {
    const fields: IFieldInfo = (
        await axiosInstance().get(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/fields`
        )
    ).data;
    mapSchemaAfterRetrieve(fields);
    return fields;
}

export async function saveFormPageFields(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fields: IFieldInfo
): Promise<IFieldInfo> {
    const postData = mapSchemaBeforeSave(fields);
    return (
        await axiosInstance().put(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/fields`,
            postData
        )
    ).data;
}

/** Form Type Version Page Labels */
export async function getFormPageFileLabels(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fileId: string
): Promise<ILabelData> {
    const data: ILabelData = (
        await axiosInstance().get(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/files/${fileId}/labels`
        )
    ).data;
    mapSchemaAfterRetrieve(data);
    return data;
}

export async function saveFormPageFileLabels(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fileId: string,
    data: ILabelData
): Promise<void> {
    const postData = mapSchemaBeforeSave(data);
    return (
        await axiosInstance().put(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/files/${fileId}/labels`,
            postData
        )
    ).data;
}

export async function clearFormPageFileLabels(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fileId: string
): Promise<void> {
    const data: ILabelData = {
        labels: [],
        tableLabels: [],
        //@ts-ignore
        schema: "",
        document: null,
        labelingState: AssetLabelingState.ManuallyLabeled,
    };
    delete data.$schema;
    return (
        await axiosInstance().put(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/files/${fileId}/labels`,
            data
        )
    ).data;
}

/** Analyze */
export async function analyzeFileLayout(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fileId: string,
    forceDataFetch: boolean = false
): Promise<FormPageArray> {
    return (
        await axiosInstance().post(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/files/${fileId}/extract`,
            {
                model: "prebuilt-layout",
                useLegacy: true,
                forceRebuild: forceDataFetch,
                useResponse: false,
            }
        )
    ).data;
}

export async function analyzeFileUsingModel(
    formName: string,
    file: File
): Promise<any> {
    const b64String = await file2Base64(file);
    try {
        const response = await axiosInstance().post(
            `/v1/forms/${formName}/analyze`,
            b64String,
            {
                headers: {
                    "Content-Type": "text/plain",
                    Accept: "*/*",
                },
            }
        );
        return response.data;
    } catch (err) {
        console.error(err);
        //throw err;
        return {
            array: [1, 2, 3],
            bool: true,
            object: {
                foo: "bar",
            },
        };
    }
}

/** Projects */
export async function loadProjectByProjectId(
    projectId: string
): Promise<IProject> {
    const formPage: IFormPage = (
        await axiosInstance().get(`/v1/forms/pages/${projectId}`)
    ).data;
    const [form, formType, formVersion] = await Promise.all([
        getForm(formPage.formId),
        getFormType(formPage.formId, formPage.formTypeId),
        getFormVersion(
            formPage.formId,
            formPage.formTypeId,
            formPage.formTypeVersionId
        ),
    ]);
    return createProjectObject({ form, formPage, formType, formVersion });
}

type LoadProjectOptions = {
    form: IForm;
    formType: IFormType;
    formVersion: IFormVersion;
    formPage: IFormPage;
};

export async function createProjectObject({
    form,
    formType,
    formVersion,
    formPage,
}: LoadProjectOptions): Promise<IProject> {
    const project: IProject = {
        id: formPage._id,
        name: `${form.name}-${formType.name}-${formVersion.name}-${formPage.name}`,
        tags: [],
        recentModelRecords: [],
        assets: {},
        form,
        formPage,
        formType,
        formVersion,
    };
    return project;
}
/** Training */
export async function trainPage(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string
): Promise<{ trainingLogId: string }> {
    return (
        await axiosInstance().post(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/train`,
            {}
        )
    ).data;
}
export async function getTrainingLogsForPage(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string
): Promise<IPageTrainingLog[]> {
    return (
        await axiosInstance().get(
            `/v1/forms/${formName}/types/${typeName}/versions/${versionName}/pages/${pageName}/training-logs`
        )
    ).data;
}

export async function rebuildFormModel(
    formName: string
): Promise<{ rebuildLogId: string }> {
    return (
        await axiosInstance().post(`/v1/forms/${formName}/rebuild-model`, {})
    ).data;
}

export async function getRebuildModelLogs(
    formName: string
): Promise<IRebuildModelTrainingLog[]> {
    return (await axiosInstance().get(`/v1/forms/${formName}/rebuild-logs`))
        .data;
}
/** Utilities */
function mapSchemaAfterRetrieve<T extends ILabelData | IFieldInfo>(
    input: T
): T {
    if (!input) {
        return;
    }
    const data = _.cloneDeep(input);
    //@ts-ignore
    data.$schema = data.schema;
    //@ts-ignore
    delete data.schema;
    return data;
}

function mapSchemaBeforeSave<T extends ILabelData | IFieldInfo>(input: T): T {
    if (!input) {
        return;
    }
    const data = _.cloneDeep(input);
    //@ts-ignore
    data.schema = data.$schema;
    delete data.$schema;
    return data;
}
