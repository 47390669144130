import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IFormType } from "../../../../models/applicationState";
import { cache } from "../../../../services/cache-service";
import {
    getFormType,
    createFormType,
    updateFormType,
} from "../../../../services/project-service";
import { GenericCreateEdit } from "../../common/generic-create-edit";
import { schema } from "./schema";

export function FormTypesCrud({ match }) {
    const history = useHistory();
    const { formId, formTypeId } = match.params;
    const [data, setData] = useState<IFormType>(null);
    useEffect(() => {
        (async () => {
            if (!formTypeId) {
                return;
            }
            try {
                const data = await getFormType(formId, formTypeId);
                setData(data);
            } catch (err) {
                console.error(err);
            }
        })();
    }, [formId, formTypeId]);
    if (formTypeId && !data) {
        return null;
    }

    return (
        <GenericCreateEdit
            title="Form Type"
            schema={schema}
            initialFormData={data}
            onAdded={onSuccess}
            onUpdated={onSuccess}
            createFunction={async (formType) =>
                createFormType(formId, formType)
            }
            updateFunction={async (formType) => {
                await updateFormType(formId, formType);
                cache.selectedType.checkUpdate(formType);
            }}
            onCancel={() => history.goBack()}
        />
    );

    function onSuccess() {
        history.push(`/select-form`);
    }
}
