import { JSONSchema6 } from "json-schema";

export const schema: JSONSchema6 = {
    title: "Form",
    type: "object",
    properties: {
        title: {
            type: "string",
            title: "Title",
            description: "The name of the form",
        },
        description: {
            type: "string",
            title: "Description",
            description: "A description for the form",
        },
    },
    required: ["title"],
};
