import React, { useState } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";

export type DropDownOptions = {
    unselectedText?: string;
    options: DropDownOption[];
    selectedOption?: DropDownOption;
    setSelectedOption: (value: Object) => void;
};

export type DropDownOption = {
    name: string;
    _id: string;
    [key: string]: any;
};
export function DropDown({
    unselectedText,
    options,
    selectedOption,
    setSelectedOption,
}: DropDownOptions) {
    unselectedText = unselectedText || "Select Item";
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Dropdown
            isOpen={isOpen}
            toggle={() => {
                setIsOpen(!isOpen);
            }}
        >
            <DropdownToggle caret>
                {selectedOption && selectedOption.title
                    ? selectedOption.title
                    : unselectedText}
            </DropdownToggle>
            <DropdownMenu>
                {options.map((item) => {
                    return (
                        <DropdownItem
                            key={item._id}
                            onClick={() => setSelectedOption(item)}
                        >
                            {item.title}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
}
