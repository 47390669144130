import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IForm } from "../../../../models/applicationState";
import { cache } from "../../../../services/cache-service";
import {
    getForm,
    createForm,
    updateForm,
} from "../../../../services/project-service";
import { GenericCreateEdit } from "../../common/generic-create-edit";
import { schema } from "./schema";

export function FormCrud({ match }) {
    const history = useHistory();
    const { formId } = match.params;
    const [form, setForm] = useState<IForm>(null);
    useEffect(() => {
        (async () => {
            if (!formId) {
                return;
            }
            try {
                const data = await getForm(formId);
                setForm(data);
            } catch (err) {
                console.error(err);
            }
        })();
    }, [formId]);
    if (formId && !form) {
        return null;
    }

    return (
        <GenericCreateEdit
            title="Form"
            schema={schema}
            initialFormData={form}
            onAdded={onSuccess}
            onUpdated={onSuccess}
            createFunction={async (formType) => createForm(formType)}
            updateFunction={async (formType) => {
                await updateForm(formType);
                cache.selectedForm.checkUpdate(formType);
            }}
            onCancel={() => history.goBack()}
        />
    );

    function onSuccess() {
        history.push(`/select-form`);
    }
}
