import { useState } from "react";

export function useLocalStorage<T>(
    key: string,
    defaultValue: T = null,
    stringifyFn: (val: T) => string = JSON.stringify,
    parseFn: (val: string) => T = JSON.parse
): [T, (T) => void] {
    const stringValue = localStorage.getItem(key);
    let parsedValue;
    try {
        parsedValue = parseFn(stringValue);
    } catch (err) {}
    const [internalValue, setInternalValue] = useState(
        parsedValue ? parsedValue : defaultValue
    );
    if (parsedValue && stringValue !== stringifyFn(internalValue)) {
        setInternalValue(parsedValue);
    }
    return [internalValue, setLocalStorage];

    function setLocalStorage(newValue: T): void {
        localStorage.setItem(key, stringifyFn(newValue));
        setInternalValue(newValue);
    }
}
