import { FormPageArray } from "@azure/ai-form-recognizer";
import {
    IFieldInfo,
    IForm,
    IFormPage,
    IFormPageFile,
    IFormType,
    IFormVersion,
    ILabelData,
    IProject,
    IRegion,
} from "../models/applicationState";
import {
    analyzeFileLayout,
    deleteFormPageFile,
    getFormPageFields,
    getFormPageFile,
    getFormPageFileLabels,
    getFormPageFiles,
    saveFormPageFields,
} from "./project-service";

// ---===[ Cache ]===---
interface Memcache {
    fields: {
        [key: string]: IFieldInfo;
    };
    files: {
        [key: string]: IFormPageFile[];
    };
}
const memCache: Memcache = {
    fields: {},
    files: {},
};

// ---===[ Generics ]===---
// interface CreateMemcacheOptions<TEntity> {
//     keyFn: (...args: any[]) => string;
//     keyName: string;
//     getFn: (...args: any[]) => Promise<TEntity>;
//     saveFn: (entity: TEntity, ...args: any[]) => Promise<any>;
// }
// interface MemcacheResult<TEntity> {
//     get: (...args: any[]) => Promise<TEntity>;
//     save: (entity: TEntity, ...args: any[]) => Promise<any>;
// }

// function createMemcache<TEntity>({
//     keyFn,
//     keyName,
//     getFn,
//     saveFn,
// }: CreateMemcacheOptions<TEntity>): MemcacheResult<TEntity> {
//     async function get(...args): Promise<TEntity> {
//         const key = keyFn(...args);
//         if (memCache[keyName][key]) {
//             return memCache[keyName][key];
//         }
//         const data = await getFn(...args);
//         memCache[keyName][key] = data;
//         return data;
//     }
//     async function save(entity: TEntity, ...args: any[]) {
//         const key = keyFn(...args);
//         await saveFn(entity, ...args);
//         memCache[keyName][key] = entity;
//     }
//     return {
//         get,
//         save,
//     };
// }

// ---===[ Projects ]===---
function loadProjectFromCache(projectId: string): IProject {
    let project: IProject = null;
    const loadedProject = localStorage.getItem(projectId);
    if (loadedProject) {
        try {
            project = JSON.parse(loadedProject);
        } catch (err) {
            localStorage.removeItem(projectId);
        }
    }
    return project;
}

function saveProjectToCache(project: IProject) {
    localStorage.setItem(project.id, JSON.stringify(project));
}

function deleteProjectFromCache(projectId: string) {
    localStorage.removeItem(projectId);
}

// ---===[ Fields ]===---

// const { get: getFieldsWithCache, save: saveFieldsWithCache } =
//     createMemcache<IFieldInfo>({
//         keyFn: (
//             formName: string,
//             typeName: string,
//             versionName: string,
//             pageName: string
//         ): string =>
//             `${formName}-${typeName}-${versionName}-${pageName}-fields`,
//         getFn: getFormPageFields,
//         saveFn: (
//             entity,
//             formName: string,
//             typeName: string,
//             versionName: string,
//             pageName: string
//         ) =>
//             saveFormPageFields(
//                 formName,
//                 typeName,
//                 versionName,
//                 pageName,
//                 entity
//             ),
//         keyName: "fields",
//     });

function fieldKey(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string
): string {
    return `${formName}-${typeName}-${versionName}-${pageName}-fields`;
}

async function getFieldsWithCache(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string
): Promise<IFieldInfo> {
    const key = fieldKey(formName, typeName, versionName, pageName);
    if (memCache.fields[key]) {
        return memCache.fields[key];
    }
    const data = await getFormPageFields(
        formName,
        typeName,
        versionName,
        pageName
    );
    memCache.fields[key] = data;
    return data;
}

async function saveFieldsWithCache(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fields: IFieldInfo
) {
    const key = fieldKey(formName, typeName, versionName, pageName);
    await saveFormPageFields(formName, typeName, versionName, pageName, fields);
    memCache.fields[key] = fields;
}

// ---===[ Files ]===---

function filesKey(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string
): string {
    return `${formName}-${typeName}-${versionName}-${pageName}-files`;
}

async function getFilesWithCache(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string
): Promise<IFormPageFile[]> {
    const key = filesKey(formName, typeName, versionName, pageName);
    if (memCache.files[key]) {
        return memCache.files[key];
    }
    const data = await getFormPageFiles(
        formName,
        typeName,
        versionName,
        pageName
    );
    memCache.files[key] = data;
    return data;
}

async function getFileWithCache(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fileId: string
): Promise<IFormPageFile> {
    const key = filesKey(formName, typeName, versionName, pageName);
    if (memCache.files[key]) {
        const files = memCache.files[key];
        const foundFile = files.find((f) => f._id === fileId);
        if (foundFile) {
            return foundFile;
        }
    }
    const data = await getFormPageFile(
        formName,
        typeName,
        versionName,
        pageName,
        fileId
    );
    if (memCache.files[key]) {
        memCache.files[key].push(data);
    } else {
        memCache.files[key] = [data];
    }

    return data;
}

async function deleteFormPageFileWithCache(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fileId: string
) {
    const key = filesKey(formName, typeName, versionName, pageName);
    await deleteFormPageFile(formName, typeName, versionName, pageName, fileId);
    if (memCache.files[key]) {
        memCache.files[key] = memCache.files[key].filter(
            (f) => f._id !== fileId
        );
    }
}

// ---===[ Labels ]===---

function labelsKey(fileId: string): string {
    return `${fileId}-ld`;
}

async function getFormPageFileLabelsFromCache(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fileId: string
): Promise<ILabelData> {
    const key = labelsKey(fileId);
    let labelData: ILabelData;
    const labelDataString = localStorage.getItem(key);
    if (labelDataString) {
        try {
            labelData = JSON.parse(labelDataString);
        } catch (err) {}
    }
    if (!labelData) {
        labelData = await getFormPageFileLabels(
            formName,
            typeName,
            versionName,
            pageName,
            fileId
        );
        if (labelData) {
            localStorage.setItem(key, JSON.stringify(labelData));
        }
    }
    return labelData;
}

function getFormPageFileLabelsFromCacheOnly(fileId: string) {
    const key = labelsKey(fileId);
    let labelData: ILabelData;
    const labelDataString = localStorage.getItem(key);
    if (labelDataString) {
        try {
            labelData = JSON.parse(labelDataString);
        } catch (err) {}
    }
    return labelData;
}

function deleteLabelDataFromCache(fileId: string) {
    const key = labelsKey(fileId);
    localStorage.removeItem(key);
}

function saveLabelDataToCache(fileId: string, labelData: ILabelData) {
    const key = labelsKey(fileId);
    localStorage.setItem(key, JSON.stringify(labelData));
}

// ---===[ Regions ]===---

function regionsKey(fileId: string): string {
    return `${fileId}-rd`;
}

function loadRegionDataCache(fileId: string): IRegion[] {
    const key = regionsKey(fileId);
    let regionData: IRegion[];
    const regionDataString = localStorage.getItem(key);
    if (regionDataString) {
        try {
            regionData = JSON.parse(regionDataString);
        } catch (err) {}
    } else {
        regionData = [];
        saveRegionDataCache(fileId, regionData);
    }
    return regionData;
}

function saveRegionDataCache(fileId: string, labelData: IRegion[]) {
    const key = regionsKey(fileId);
    localStorage.setItem(key, JSON.stringify(labelData));
}

function deleteRegionDataCache(fileId: string) {
    const key = regionsKey(fileId);
    localStorage.removeItem(key);
}

// ---===[ Analyze Result ]===---
function analyzeFileLayoutKey(fileId: string): string {
    return fileId;
}

async function analyzeFileLayoutWithCache(
    formName: string,
    typeName: string,
    versionName: string,
    pageName: string,
    fileId: string,
    forceDataFetch: boolean = false
): Promise<FormPageArray> {
    const key = analyzeFileLayoutKey(fileId);
    let data: FormPageArray;
    const analyzeLayoutString = localStorage.getItem(key);
    if (analyzeLayoutString) {
        try {
            data = JSON.parse(analyzeLayoutString);
            if (!forceDataFetch) {
                return data;
            }
        } catch (err) {}
    }
    data = await analyzeFileLayout(
        formName,
        typeName,
        versionName,
        pageName,
        fileId,
        forceDataFetch
    );
    saveAnalyzeFileLayoutToCache(fileId, data);
    return data;
}

function saveAnalyzeFileLayoutToCache(fileId: string, data: FormPageArray) {
    const key = analyzeFileLayoutKey(fileId);
    localStorage.setItem(key, JSON.stringify(data));
}

function deleteAnalyzeFileLayoutFromCache(fileId: string) {
    const key = analyzeFileLayoutKey(fileId);
    localStorage.removeItem(key);
}
// ---===[ Auth ]===---

const authStorageKey = "apiKey";

function getAuthKey() {
    return localStorage.getItem(authStorageKey);
}
function saveAuthKey(apiKey: string) {
    localStorage.setItem(authStorageKey, apiKey);
}
function deleteAuthKey() {
    localStorage.removeItem(authStorageKey);
}

// ---===[ Forms ]===---

const selectedFormKey = "selected-form";

function getSelectedFormFromCache(): IForm {
    const stringData = localStorage.getItem(selectedFormKey);
    if (!stringData) {
        return;
    }
    try {
        return JSON.parse(stringData);
    } catch (err) {
        localStorage.removeItem(selectedFormKey);
    }
}

function saveSelectedFormToCache(form: IForm) {
    localStorage.setItem(selectedFormKey, JSON.stringify(form));
}

function checkUpdateCachedForm(form: IForm) {
    const cachedData = getSelectedFormFromCache();
    if (form._id !== cachedData._id) {
        return;
    }
    saveSelectedFormToCache(form);
}

// ---===[ FormTypes ]===---

const selectedTypeKey = "selected-type";

function getSelectedTypeFromCache(): IFormType {
    const stringData = localStorage.getItem(selectedTypeKey);
    if (!stringData) {
        return;
    }
    try {
        return JSON.parse(stringData);
    } catch (err) {
        localStorage.removeItem(selectedTypeKey);
    }
}

function saveSelectedTypeToCache(formType: IFormType) {
    localStorage.setItem(selectedPageKey, JSON.stringify(formType));
}

function checkUpdateCachedType(formType: IFormType) {
    const cachedData = getSelectedFormFromCache();
    if (formType._id !== cachedData._id) {
        return;
    }
    saveSelectedFormToCache(formType);
}

// ---===[ Form Versions ]===---

const selectedVersionKey = "selected-version";

function getSelectedVersionFromCache(): IFormVersion {
    const stringData = localStorage.getItem(selectedTypeKey);
    if (!stringData) {
        return;
    }
    try {
        return JSON.parse(stringData);
    } catch (err) {
        localStorage.removeItem(selectedTypeKey);
    }
}

function saveSelectedVersionToCache(version: IFormVersion) {
    localStorage.setItem(selectedPageKey, JSON.stringify(version));
}

function checkUpdateCachedVersion(version: IFormVersion) {
    const cachedData = getSelectedVersionFromCache();
    if (version._id !== cachedData._id) {
        return;
    }
    saveSelectedVersionToCache(version);
}

// ---===[ Form Pages ]===---

const selectedPageKey = "selected-page";

function getSelectedPageFromCache(): IFormPage {
    const pageString = localStorage.getItem(selectedPageKey);
    if (!pageString) {
        return;
    }
    try {
        return JSON.parse(pageString);
    } catch (err) {
        localStorage.removeItem(selectedPageKey);
    }
}

function saveSelectedPageToCache(page: IFormPage) {
    localStorage.setItem(selectedPageKey, JSON.stringify(page));
}

function checkUpdateCachedPage(page: IFormPage) {
    const cachedPage = getSelectedPageFromCache();
    if (page._id !== cachedPage._id) {
        return;
    }
    saveSelectedPageToCache(page);
}

// ---===[ Cache Object ]===---

export const cache = {
    project: {
        load: loadProjectFromCache,
        save: saveProjectToCache,
        delete: deleteProjectFromCache,
    },
    fields: {
        get: getFieldsWithCache,
        save: saveFieldsWithCache,
    },
    files: {
        get: getFilesWithCache,
    },
    file: {
        get: getFileWithCache,
        delete: deleteFormPageFileWithCache,
    },
    labels: {
        get: getFormPageFileLabelsFromCache,
        getCacheOnly: getFormPageFileLabelsFromCacheOnly,
        delete: deleteLabelDataFromCache,
        save: saveLabelDataToCache,
    },
    regions: {
        get: loadRegionDataCache,
        save: saveRegionDataCache,
        delete: deleteRegionDataCache,
    },
    analyzeLayout: {
        get: analyzeFileLayoutWithCache,
        save: saveAnalyzeFileLayoutToCache,
        delete: deleteAnalyzeFileLayoutFromCache,
    },
    auth: {
        get: getAuthKey,
        save: saveAuthKey,
        delete: deleteAuthKey,
    },
    selectedForm: {
        get: getSelectedFormFromCache,
        save: saveSelectedFormToCache,
        checkUpdate: checkUpdateCachedForm,
        cacheKey: selectedFormKey,
    },
    selectedType: {
        get: getSelectedTypeFromCache,
        save: saveSelectedTypeToCache,
        checkUpdate: checkUpdateCachedType,
        cacheKey: selectedTypeKey,
    },
    selectedVersion: {
        get: getSelectedVersionFromCache,
        save: saveSelectedVersionToCache,
        checkUpdate: checkUpdateCachedVersion,
        cacheKey: selectedVersionKey,
    },
    selectedPage: {
        get: getSelectedPageFromCache,
        save: saveSelectedPageToCache,
        checkUpdate: checkUpdateCachedPage,
        cacheKey: selectedPageKey,
    },
};
