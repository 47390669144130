import React, { useState } from "react";
import Form, { IChangeEvent } from "react-jsonschema-form";
import { JSONSchema6 } from "json-schema";
import { useDispatch } from "react-redux";
import { ErrorCode, IApiMetadata } from "../../../../models/applicationState";
import { showError } from "../../../../redux/actions/appErrorActions";
import { Button } from "reactstrap";
import { toast } from "react-toastify";

export type CreateEditFormProps<T extends IApiMetadata> = {
    schema: JSONSchema6;
    initialFormData?: T;
    title: string;
    createFunction: (formToCreate: T) => Promise<T>;
    updateFunction: (formToCreate: T) => Promise<void>;
    onCancel: () => void;
    onAdded?: (createdForm: T) => void;
    onUpdated?: (updatedForm: T) => void;
};

export function GenericCreateEdit<T extends IApiMetadata>({
    initialFormData,
    onAdded,
    onUpdated,
    schema,
    createFunction,
    updateFunction,
    title,
    onCancel,
}: CreateEditFormProps<T>) {
    //@ts-ignore
    const [data, setData] = useState<T>(initialFormData);
    const dispatch = useDispatch();
    return (
        <div>
            <h2>
                {initialFormData ? "Edit" : "Create"} {title}
            </h2>
            <Form schema={schema} onSubmit={submitted} formData={data}>
                <Button color="secondary" type="button" onClick={onCancel}>
                    Cancel
                </Button>
                <Button color="primary" type="submit">
                    {initialFormData ? "Save " : "Create"}
                </Button>
            </Form>
        </div>
    );

    async function submitted({ edit, formData }: IChangeEvent<T>) {
        try {
            if (edit && formData._id) {
                await updateFunction(formData);
                toast.success(
                    `Successfully updated "${formData.title}" ${title}.`,
                    { autoClose: 8000 }
                );
                if (onUpdated) {
                    const updatedForm = { ...initialFormData, ...formData };
                    onUpdated(updatedForm);
                }
                return;
            }

            const created = await createFunction(formData);
            toast.success(
                `Successfully created "${formData.title}" ${title}.`,
                { autoClose: 8000 }
            );
            if (onAdded) {
                onAdded(created);
            }
        } catch (err) {
            let title, message;
            if (err.response && err.response.data) {
                message = err.response.data.message;
                title = `${
                    err.response.data.statusCode
                        ? err.response.data.statusCode + " "
                        : ""
                }${err.response.data.error}`;
            }
            message = message || "Unknown Error";
            title = title || "Error";
            setData(formData);
            dispatch(
                showError({
                    errorCode: ErrorCode.Unknown,
                    message,
                    title,
                })
            );
        }
    }
}
