import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IFormVersion } from "../../../../models/applicationState";
import { cache } from "../../../../services/cache-service";

import {
    getFormVersion,
    createFormVersion,
    updateFormTypeVersion,
} from "../../../../services/project-service";
import { GenericCreateEdit } from "../../common/generic-create-edit";
import { schema } from "./schema";

export function FormTypeVersionsCrud({ match }) {
    const history = useHistory();
    const { formId, formTypeId, formTypeVersionId } = match.params;
    const [data, setData] = useState<IFormVersion>(null);
    useEffect(() => {
        (async () => {
            if (!formTypeId) {
                return;
            }
            try {
                const data = await getFormVersion(
                    formId,
                    formTypeId,
                    formTypeVersionId
                );
                setData(data);
            } catch (err) {
                console.error(err);
            }
        })();
    }, [formId, formTypeId, formTypeVersionId]);
    if (formTypeId && !data) {
        return null;
    }

    return (
        <GenericCreateEdit
            title="Form Type Version"
            schema={schema}
            initialFormData={data}
            onAdded={onSuccess}
            onUpdated={onSuccess}
            createFunction={async (formTypeVersion) =>
                createFormVersion(formId, formTypeId, formTypeVersion)
            }
            updateFunction={async (formTypeVersion) => {
                await updateFormTypeVersion(
                    formId,
                    formTypeId,
                    formTypeVersion
                );
                cache.selectedVersion.checkUpdate(formTypeVersion);
            }}
            onCancel={() => history.goBack()}
        />
    );

    function onSuccess() {
        history.push(`/select-form`);
    }
}
