import React, { useState, useEffect } from "react";
import { InputGroup, Button } from "reactstrap";
import { DropDown } from "./drop-down";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showError } from "../../../../redux/actions/appErrorActions";
import { loadProject } from "../../../../redux/actions/projectActions";
import { ErrorCode } from "../../../../models/applicationState";
import {
    IForm,
    IFormType,
    IFormVersion,
    IFormPage,
} from "../../../../models/applicationState";
import {
    createProjectObject,
    getFormPages,
    getFormTypes,
    getFormVersions,
    getForms,
} from "../../../../services/project-service";
import {
    analyzeFileRoute,
    editFormTypeVersionPageFilesRoute,
    trainFormTypeVersionPageFilesRoute,
    trainModelRoute,
} from "../../shell/mainContentRouter";
import { Crud } from "./crud";
import { useLocalStorage } from "./use-local-storage";
import { cache } from "../../../../services/cache-service";

export function SelectFormPage() {
    const history = useHistory();
    const [initialRequestDone, setInitialRequestDone] = useState(false);
    const [forms, setForms] = useState<IForm[]>(null);
    const [selectedForm, setSelectedForm] = useLocalStorage<IForm>(
        cache.selectedForm.cacheKey,
        null
    );
    const [types, setTypes] = useState<IFormType[]>(null);
    const [selectedType, setSelectedType] = useLocalStorage<IFormType>(
        cache.selectedType.cacheKey,
        null
    );
    const [versions, setVersions] = useState<IFormVersion[]>(null);
    const [selectedVersion, setSelectedVersion] = useLocalStorage<IFormPage>(
        cache.selectedVersion.cacheKey,
        null
    );
    const [pages, setPages] = useState<IFormPage[]>(null);
    const [selectedPage, setSelectedPage] = useLocalStorage<IFormPage>(
        cache.selectedPage.cacheKey,
        null
    );

    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            const response = await getForms();
            setForms(response);
            setInitialRequestDone(true);
        }
        fetchData().catch((err) => {
            console.error(err);
        });
    }, []);

    useEffect(() => {
        async function fetchData() {
            const response = await getFormTypes(selectedForm.name);
            setTypes(response);
        }
        if (!selectedForm) {
            return;
        }
        fetchData().catch((err) => {
            console.error(err);
        });
    }, [selectedForm]);

    useEffect(() => {
        async function fetchData() {
            const response = await getFormVersions(
                selectedForm.name,
                selectedType.name
            );
            setVersions(response);
        }
        if (!selectedType) {
            return;
        }
        fetchData().catch((err) => {
            console.error(err);
        });
    }, [selectedForm, selectedType]);

    useEffect(() => {
        async function fetchData() {
            const response = await getFormPages(
                selectedForm.name,
                selectedType.name,
                selectedVersion.name
            );
            setPages(response);
        }
        if (!selectedVersion) {
            return;
        }
        fetchData().catch((err) => {
            console.error(err);
        });
    }, [selectedForm, selectedType, selectedVersion]);
    return (
        <div>
            <h1>Select Form Page</h1>

            {!initialRequestDone && <div>Loading...</div>}
            {forms && forms.length > 0 && (
                <div>
                    <InputGroup>
                        <DropDown
                            options={forms}
                            setSelectedOption={(option: IForm) => {
                                setSelectedForm(option);
                                setSelectedType(null);
                                setSelectedVersion(null);
                                setSelectedPage(null);
                            }}
                            selectedOption={selectedForm}
                            unselectedText="Select Form"
                        />
                        {selectedForm && types && (
                            <DropDown
                                options={types}
                                setSelectedOption={(option: IFormType) => {
                                    setSelectedType(option);
                                    setSelectedVersion(null);
                                    setSelectedPage(null);
                                }}
                                selectedOption={selectedType}
                                unselectedText="Select Type"
                            />
                        )}
                        {selectedForm && selectedType && versions && (
                            <DropDown
                                options={versions}
                                setSelectedOption={(option: IFormVersion) => {
                                    setSelectedVersion(option);
                                    setSelectedPage(null);
                                }}
                                selectedOption={selectedVersion}
                                unselectedText="Select Version"
                            />
                        )}
                        {selectedForm &&
                            selectedType &&
                            selectedVersion &&
                            pages && (
                                <DropDown
                                    options={pages}
                                    setSelectedOption={setSelectedPage}
                                    selectedOption={selectedPage}
                                    unselectedText="Select Page"
                                />
                            )}
                    </InputGroup>
                    <br />
                    <Crud
                        selectedForm={selectedForm}
                        selectedPage={selectedPage}
                        selectedType={selectedType}
                        selectedVersion={selectedVersion}
                    />
                    <br />
                    {selectedForm &&
                        selectedType &&
                        selectedVersion &&
                        selectedPage && (
                            <div>
                                <h2>Actions</h2>
                                <InputGroup>
                                    <Button
                                        color="secondary"
                                        type="submit"
                                        onClick={() =>
                                            history.push(
                                                editFormTypeVersionPageFilesRoute(
                                                    selectedForm.name,
                                                    selectedType.name,
                                                    selectedVersion.name,
                                                    selectedPage.name
                                                )
                                            )
                                        }
                                    >
                                        Manage Files
                                    </Button>
                                    <Button
                                        color="secondary"
                                        type="submit"
                                        onClick={() =>
                                            history.push(
                                                analyzeFileRoute(
                                                    selectedForm.name
                                                )
                                            )
                                        }
                                    >
                                        Analyze
                                    </Button>
                                    <Button
                                        color="secondary"
                                        type="submit"
                                        onClick={goToLabel}
                                        disabled={!allValuesSelected}
                                    >
                                        Label
                                    </Button>
                                    <Button
                                        color="secondary"
                                        type="submit"
                                        onClick={() =>
                                            history.push(
                                                trainFormTypeVersionPageFilesRoute(
                                                    selectedForm.name,
                                                    selectedType.name,
                                                    selectedVersion.name,
                                                    selectedPage.name
                                                )
                                            )
                                        }
                                    >
                                        Train Page
                                    </Button>
                                </InputGroup>
                            </div>
                        )}
                    {selectedForm && (
                        <div>
                            <Button
                                color="secondary"
                                type="submit"
                                onClick={() =>
                                    history.push(
                                        trainModelRoute(selectedForm.name)
                                    )
                                }
                            >
                                Train Model
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );

    async function goToLabel() {
        if (!allValuesSelected()) {
            console.warn("Not all values selected");
            dispatch(
                showError({
                    errorCode: ErrorCode.Unknown,
                    message: "Not all values selected",
                    title: "Invalid",
                })
            );
            //@ts-ignore

            return;
        }
        const project = await createProjectObject({
            form: selectedForm,
            formType: selectedType,
            formVersion: selectedVersion,
            formPage: selectedPage,
        });
        await dispatch(loadProject(project));
        history.push(`/projects/${project.id}/edit`);
    }

    function allValuesSelected() {
        return !!(
            selectedForm &&
            selectedType &&
            selectedVersion &&
            selectedPage
        );
    }
}
