import React from "react";
import {
    createFormRoute,
    editFormRoute,
    createFormTypeRoute,
    editFormTypeRoute,
    createFormTypeVersionPageRoute,
    createFormTypeVersionRoute,
    editFormTypeVersionPageRoute,
    editFormTypeVersionRoute,
} from "../../shell/mainContentRouter";
import {
    IForm,
    IFormType,
    IFormVersion,
    IFormPage,
} from "../../../../models/applicationState";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

type CrudProps = {
    selectedForm?: IForm;
    selectedType?: IFormType;
    selectedVersion?: IFormVersion;
    selectedPage?: IFormPage;
};

export function Crud({
    selectedForm,
    selectedPage,
    selectedType,
    selectedVersion,
}: CrudProps) {
    const history = useHistory();
    return (
        <div>
            <h2>Add or Edit</h2>
            <Button
                color="secondary"
                type="button"
                onClick={() =>
                    selectedForm
                        ? history.push(editFormRoute(selectedForm.name))
                        : history.push(createFormRoute())
                }
            >
                {selectedForm ? "Edit " + selectedForm.title : "Add form"}
            </Button>
            {selectedForm && (
                <Button
                    color="secondary"
                    type="button"
                    onClick={() =>
                        selectedType
                            ? history.push(
                                  editFormTypeRoute(
                                      selectedForm.name,
                                      selectedType.name
                                  )
                              )
                            : history.push(
                                  createFormTypeRoute(selectedForm.name)
                              )
                    }
                >
                    {selectedType
                        ? "Edit " + selectedType.title
                        : "Add form type"}
                </Button>
            )}
            {selectedType && (
                <Button
                    color="secondary"
                    type="button"
                    onClick={() =>
                        selectedVersion
                            ? history.push(
                                  editFormTypeVersionRoute(
                                      selectedForm.name,
                                      selectedType.name,
                                      selectedVersion.name
                                  )
                              )
                            : history.push(
                                  createFormTypeVersionRoute(
                                      selectedForm.name,
                                      selectedType.name
                                  )
                              )
                    }
                >
                    {selectedVersion
                        ? "Edit " + selectedVersion.title
                        : "Add form version"}
                </Button>
            )}
            {selectedVersion && (
                <Button
                    color="secondary"
                    type="button"
                    onClick={() =>
                        selectedPage
                            ? history.push(
                                  editFormTypeVersionPageRoute(
                                      selectedForm.name,
                                      selectedType.name,
                                      selectedVersion.name,
                                      selectedPage.name
                                  )
                              )
                            : history.push(
                                  createFormTypeVersionPageRoute(
                                      selectedForm.name,
                                      selectedType.name,
                                      selectedVersion.name
                                  )
                              )
                    }
                >
                    {selectedPage
                        ? "Edit " + selectedPage.title
                        : "Add form version page"}
                </Button>
            )}
        </div>
    );
}
