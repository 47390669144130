import { getApiKey, isLoggedIn } from "../providers/authentication";
import axios, { AxiosInstance } from "axios";

let instance: AxiosInstance = null;

export function axiosInstance() {
    if (!isLoggedIn()) {
        return null;
    }
    if (!instance) {
        instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: { "x-api-key": getApiKey() },
        });
    }
    return instance;
}
