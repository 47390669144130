import React, { useState } from "react";
import { Formik, Form, Field, FormikErrors } from "formik";
import { InputGroup, Input, Button, FormFeedback } from "reactstrap";
import {
    validateApiKey,
    isLoggedIn,
} from "../../../../providers/authentication";
import { Redirect } from "react-router-dom";
import { postLoginRoute } from "../../shell/mainContentRouter";

type LoginFormValues = {
    apiKey?: string;
};

export function LoginPage() {
    const [error, setError] = useState("");
    const [apiKey, setApiKey] = useState(null);
    if (isLoggedIn() || apiKey) {
        return <Redirect to={postLoginRoute} />;
    }
    return (
        <div>
            <h1>Login</h1>
            <Formik
                initialValues={{
                    apiKey: "",
                }}
                validate={validateLoginForm}
                onSubmit={submitLoginForm}
            >
                {({ isSubmitting, errors, touched }) => (
                    <Form>
                        <InputGroup>
                            <Input
                                name="apiKey"
                                tag={Field}
                                invalid={!!(errors.apiKey && touched.apiKey)}
                            />
                            <FormFeedback tooltip>{errors.apiKey}</FormFeedback>
                        </InputGroup>
                        {error && <div>{error}</div>}
                        <Button
                            color="secondary"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
            <div>
                Environment: <b>{process.env.NODE_ENV}</b>
            </div>
        </div>
    );
    function validateLoginForm(
        values: LoginFormValues
    ): FormikErrors<LoginFormValues> {
        const errors: FormikErrors<LoginFormValues> = {};
        if (!values.apiKey) {
            errors.apiKey = "apiKey is required";
        }
        return errors;
    }
    async function submitLoginForm(values: LoginFormValues, helpers) {
        setError("");
        const { setSubmitting } = helpers;
        const result = await validateApiKey(values.apiKey);
        setSubmitting(false);
        if (result.valid) {
            setApiKey(result.apiKey);
            return;
        }
        setError(result.errorMessage);

        // setUser(result.body.user);
        // setError("");
    }
}
