import React, { useRef, useState } from "react";
import { analyzeFileUsingModel } from "../../../../services/project-service";
import { JSONTree } from "react-json-tree";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";

export function AnalyzeFilePage({ match }) {
    const { formId } = match.params;
    const history = useHistory();
    const uploadRef = useRef<HTMLInputElement>();
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(false);
    return (
        <div>
            <h1>Analyze file for {formId}</h1>
            <h2>Upload file to analyze</h2>
            {!loading && (
                <input
                    ref={uploadRef}
                    style={{ display: "block" }}
                    type="file"
                    multiple={false}
                    accept=".pdf"
                    onChange={async () => {
                        const uploadControl = uploadRef.current;
                        if (!uploadControl) {
                            return;
                        }
                        setLoading(true);
                        setContent(null);
                        const filesToUpload: FileList = uploadControl.files;
                        if (!filesToUpload || filesToUpload.length === 0) {
                            return;
                        }
                        const res = await analyzeFileUsingModel(
                            formId,
                            filesToUpload[0]
                        );
                        setContent(res);
                        setLoading(false);
                        uploadControl.value = "";
                    }}
                />
            )}
            {loading && (
                <div className="asset-loading">
                    <div className="asset-loading-spinner">
                        <Spinner size={SpinnerSize.small} />
                    </div>
                </div>
            )}
            {content && (
                <div style={{ height: "80vh", overflow: "auto" }}>
                    <h2>Analyze Results</h2>
                    <JSONTree data={content} />
                </div>
            )}
            <br />
            <Button
                color="secondary"
                type="submit"
                onClick={() => history.push("/")}
            >
                Back
            </Button>
        </div>
    );
}
