import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    rebuildFormModel,
    getRebuildModelLogs,
} from "../../../../services/project-service";
import { InputGroup, Button } from "reactstrap";
import { IRebuildModelTrainingLog } from "../../../../models/applicationState";
import { toast } from "react-toastify";

export function TrainingModelPage({ match }) {
    const history = useHistory();
    const [logs, setLogs] = useState<IRebuildModelTrainingLog[]>([]);

    const { formId } = match.params;

    useEffect(() => {
        (async () => {
            const logResult = await getRebuildModelLogs(formId);
            setLogs(logResult);
        })();
    }, [formId]);
    return (
        <div className="data-table-container">
            {logs.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Log Id</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Status</th>
                            <th>Error</th>
                            <th>Log</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs.map((log) => (
                            <tr key={log._id}>
                                <td>{log.logId}</td>
                                <td>{log.rebuildStartDate}</td>
                                <td>{log.rebuildCompletedDate}</td>
                                <td>{log.status}</td>
                                <td>
                                    {typeof log.error === "string"
                                        ? log.error
                                        : JSON.stringify(log.error)}
                                </td>
                                <td>
                                    <pre>
                                        {log.statusLog.reduce(
                                            (previous, current) => {
                                                return (
                                                    previous + "\n" + current
                                                );
                                            },
                                            ""
                                        )}
                                    </pre>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : null}
            <InputGroup>
                <Button
                    color="secondary"
                    type="submit"
                    onClick={() => history.push("/")}
                >
                    Back
                </Button>
                <Button
                    color="secondary"
                    type="submit"
                    onClick={async () => {
                        const res = await rebuildFormModel(formId);
                        toast.success(
                            `Training model job queued with ID: ${res.rebuildLogId}`
                        );
                    }}
                >
                    Train Model
                </Button>
            </InputGroup>
        </div>
    );
}
