import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { IFormPageFile } from "../../../../models/applicationState";
import { loadProject } from "../../../../redux/actions/projectActions";
import {
    downloadFile,
    uploadFile,
    loadProjectByProjectId,
    deleteFormPageFile,
} from "../../../../services/project-service";
import { cache } from "../../../../services/cache-service";

export function FormTypeVersionPagesFiles({ match }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const uploadRef = useRef<HTMLInputElement>();
    const { formId, formTypeId, formTypeVersionId, formTypeVersionPageId } =
        match.params;
    const [files, setFiles] = useState<IFormPageFile[]>();
    useEffect(() => {
        (async () => {
            const results = await cache.files.get(
                formId,
                formTypeId,
                formTypeVersionId,
                formTypeVersionPageId
            );
            setFiles(results);
        })();
    }, [formId, formTypeId, formTypeVersionId, formTypeVersionPageId]);

    return (
        <div>
            <h1>Edit files</h1>
            {files && (
                <table>
                    <thead>
                        <tr>
                            <th>File name</th>
                            <th>File type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.map((file) => (
                            <tr key={file._id}>
                                <td>{file.filename}</td>
                                <td>{file.extension}</td>
                                <td>
                                    <button
                                        onClick={async () => {
                                            await deleteFormPageFile(
                                                formId,
                                                formTypeId,
                                                formTypeVersionId,
                                                formTypeVersionPageId,
                                                file._id
                                            );
                                            setFiles([
                                                ...files.filter(
                                                    (f) => f._id !== file._id
                                                ),
                                            ]);
                                        }}
                                    >
                                        delete
                                    </button>
                                    <button
                                        onClick={() =>
                                            downloadFile(
                                                formId,
                                                formTypeId,
                                                formTypeVersionId,
                                                formTypeVersionPageId,
                                                file._id
                                            )
                                        }
                                    >
                                        download
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <h2>Upload new file</h2>
            <input
                ref={uploadRef}
                style={{ display: "block" }}
                type="file"
                multiple={true}
                accept=".pdf"
                onChange={async () => {
                    const uploadControl = uploadRef.current;
                    if (!uploadControl) {
                        return;
                    }
                    const filesToUpload: FileList = uploadControl.files;
                    if (!filesToUpload) {
                        return;
                    }
                    const promises = Array.from(filesToUpload).map((file) => {
                        return uploadFile(
                            formId,
                            formTypeId,
                            formTypeVersionId,
                            formTypeVersionPageId,
                            file
                        );
                    });
                    const newFiles = await Promise.all(promises);
                    setFiles([...files, ...newFiles]);
                    uploadControl.value = "";
                }}
            />
            <br />
            <Button
                color="secondary"
                type="submit"
                onClick={() => history.push("/")}
            >
                Back
            </Button>
            <Button color="secondary" type="submit" onClick={goToLabel}>
                Label
            </Button>
        </div>
    );

    async function goToLabel() {
        const project = await loadProjectByProjectId(formTypeVersionPageId);
        await dispatch(loadProject(project));
        history.push(`/projects/${project.id}/edit`);
    }
}
